<script lang="ts" setup>
import { ChevronDownIcon, XIcon, SearchIcon, MenuIcon, UserIcon, ShoppingCartIcon } from 'lucide-vue-next';
import { Accordion, AccordionTrigger, AccordionContent, AccordionItem } from '@/components/ui/accordion';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Button } from '@/components/ui/button';
import { SfDrawer, useDisclosure } from '@storefront-ui/vue';

const config = useRuntimeConfig();
const localePath = useLocalePath();

const { close: closMobileMenu, open: openMobileMenu, isOpen: isOpenMobileMenu } = useDisclosure();

const { data: cart } = useCart();
const { data: bootstrap } = useBootstrap();
const data = ref<any>(null);
const showMobileSearch = ref(false);

const current = computed(() => {
  return bootstrap.value?.menuItems.find((menu: any) => menu.id == data.value);
});

const toggleMobileSearch = () => {
  showMobileSearch.value = !showMobileSearch.value;
};
</script>

<template>
  <header class="z-40 top-0 bg-gray-100 min-h-14 pt-2 border-b">
    <VContainer class="flex items-center justify-between gap-3">
      <div class="flex items-center w-full gap-3">
        <NuxtLink :to="localePath('/')" class="flex w-auto shrink-0">
          <Logo class="max-w-32 lg:max-w-36 xl:max-w-40" />
        </NuxtLink>
        <VSearch class="hidden lg:block" />
      </div>
      <div class="flex items-center gap-1">
        <div class="block lg:hidden">
          <button class="group relative flex items-center justify-center size-10" @click="toggleMobileSearch">
            <SearchIcon stroke-width="1" stroke="currentColor" class="size-8" />
          </button>
        </div>
        <div>
          <NuxtLink
            :to="localePath('/cart')"
            class="hover:bg-gray-200/80 rounded-sm px-2 group relative gap-2 flex items-center justify-center h-10"
          >
            <span class="relative">
              <ShoppingCartIcon stroke-width="1" stroke="currentColor" class="size-8 md:size-7" />
              <span class="absolute top-0 right-2" v-if="(cart?.products_count ?? 0) > 0">
                <span class="relative flex h-2 w-2">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-2 w-2 bg-primary-500"></span>
                </span>
              </span>
            </span>
            <span class="hidden md:block">{{ $t('Cart') }}</span>
            <span class="sr-only">{{ $t('items in cart, view bag') }}</span>
          </NuxtLink>
        </div>
        <div>
          <NuxtLink
            :to="localePath('/my-account')"
            :title="$t('Account')"
            class="hover:bg-gray-200/80 rounded-sm px-2 group flex gap-2 items-center justify-center h-10"
          >
            <UserIcon stroke-width="1" stroke="currentColor" class="size-8 md:size-7" />
            <span class="hidden md:block">{{ $t('Account') }}</span>
          </NuxtLink>
        </div>
        <!-- Mobile Menu Button -->
        <div class="flow-root">
          <button type="button" class="lg:hidden size-10 flex items-center justify-center" @click="openMobileMenu" title="menu">
            <MenuIcon stroke-width="1" stroke="currentColor" class="size-8" />
          </button>
        </div>
      </div>
    </VContainer>

    <VContainer class="hidden lg:flex items-center gap-3 py-2 overflow-x-auto" @mouseleave="data = null">
      <div v-for="menu in bootstrap?.menuItems" :key="menu.id">
        <NuxtLink
          @mouseenter="data = menu.id"
          class="h-9 px-2 rounded bg-gray-100 hover:bg-white transition whitespace-nowrap flex gap-2 items-center"
          :to="menu.url.replace(config.public.prestashop_url, '')"
          :class="{ 'bg-white': data == menu.id }"
        >
          {{ menu.label }}
          <ChevronDownIcon v-if="menu.children.length > 0" class="h-4 w-4 [data-open]:rotate-180" :open="data == menu.id" />
        </NuxtLink>
      </div>
      <div v-if="config.public.app_theme == 'blue'">
        <NuxtLink
          prefetch
          @mouseenter="data = 'brands'"
          class="h-9 px-2 rounded bg-gray-100 hover:bg-white transition whitespace-nowrap flex gap-2 items-center"
          :class="{ 'bg-white': data == 'brands' }"
          :to="localePath('/brands')"
        >
          {{ $t('Brands') }}
        </NuxtLink>
      </div>
      <div v-if="current && current.children.length > 0" class="absolute top-[141px] left-0 z-50 flex items-center w-full">
        <VContainer class="bg-white rounded-sm w-full border shadow-sm" @click="data = null">
          <ul class="columns-3 gap-3 py-2">
            <li class="row-span-3 pb-3 pl-8 relative" v-for="item in current.children" :key="item.id">
              <NuxtLink
                :to="item.url.replace(config.public.prestashop_url, '')"
                class="font-semibold hover:underline flex items-center"
              >
                <img v-if="item.images?.small" :src="`${item.images?.small}`" class="absolute -left-1 size-6" />
                {{ item.label }}
              </NuxtLink>
              <ul class="py-1 w-full" v-if="item.children.length > 0">
                <li class="row-span-3" v-for="subitem in item.children" :key="subitem.id">
                  <NuxtLink :to="subitem.url.replace(config.public.prestashop_url, '')" class="hover:underline">
                    {{ subitem.label }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </ul>
        </VContainer>
      </div>
    </VContainer>

    <!-- Mobile Search -->
    <VContainer v-show="showMobileSearch" class="flex items-center justify-end p-3 lg:hidden">
      <VSearch />
    </VContainer>

    <!-- Mobile navigation -->
    <div v-if="isOpenMobileMenu" class="lg:hidden fixed inset-0 bg-neutral-500 bg-opacity-50 z-40" />
    <transition
      enter-active-class="transition duration-500 ease-in-out"
      leave-active-class="transition duration-500 ease-in-out"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
    >
      <SfDrawer
        ref="mobileDrawer"
        v-model="isOpenMobileMenu"
        placement="left"
        class="lg:hidden right-[50px] max-w-[376px] bg-white overflow-y-auto z-50"
      >
        <nav class="px-4 text-base">
          <div class="flex items-center pb-1 justify-end border-b border-b-neutral-200 border-b-solid">
            <Button aria-label="Close menu" variant="ghost" class="ml-2 p-3" @click="closMobileMenu">
              <XIcon class="h-8 text-neutral-500" />
            </Button>
          </div>
          <Accordion type="single" collapsible class="w-full" default-value="3">
            <template v-for="item in bootstrap?.menuItems" :key="item.id">
              <div class="py-1" v-if="item.children.length == 0">
                <NuxtLink :to="item.url.replace(config.public.prestashop_url, '')" @click="closMobileMenu" class="p-2 block hover:bg-gray-100">
                  {{ item.label }}
                </NuxtLink>
              </div>
              <AccordionItem v-else :value="item.id" class="py-1 outline-none">
                <div class="w-full flex items-center justify-between">
                  <NuxtLink :to="item.url.replace(config.public.prestashop_url, '')" @click="closMobileMenu" class="p-2 w-full block rounded-sm hover:bg-gray-100">
                    {{ item.label }}
                  </NuxtLink>
                  <AccordionTrigger class="grow flex gap-3 py-2 w-12 h-9 justify-center rounded-sm leading-6 hover:bg-gray-100" />
                </div>
                <AccordionContent class="p-0">
                  <template v-for="(collapsible, index) in item.children" :key="index">
                    <div class="py-1 ps-2" v-if="collapsible.children.length == 0">
                      <NuxtLink :to="collapsible.url.replace(config.public.prestashop_url, '')" @click="closMobileMenu" class="flex items-center gap-3 h-9 hover:bg-gray-100">
                        <img v-if="collapsible.images?.small" :src="`${collapsible.images?.small}`" class="size-9" />
                        <p class="w-9" v-else></p>
                        <span>{{ collapsible.label }}</span>
                      </NuxtLink>
                    </div>
                    <Collapsible v-else class="py-1" :open="open">
                      <div class="flex items-center ps-2 justify-between gap-1">
                        <NuxtLink :to="collapsible.url.replace(config.public.prestashop_url, '')" @click="closMobileMenu" class="flex w-full rounded-sm items-center gap-3 hover:bg-gray-100">
                          <img class="size-9" v-if="collapsible.images?.small" :src="`${collapsible.images?.small}`" />
                          <span>{{ collapsible.label }}</span>
                        </NuxtLink>
                        <CollapsibleTrigger
                          class="hover:bg-gray-100 rounded-sm flex h-9 w-12 shrink-0 justify-center gap-2 items-center leading-6"
                        >
                          <ChevronDownIcon
                            class="mt-1 shrink-0 size-4 opacity-60 transition-transform duration-200"
                            aria-hidden="true"
                          />
                        </CollapsibleTrigger>
                      </div>
                      <CollapsibleContent class="mt-1 ps-4 py-2 overflow-hidden transition-all">
                        <div class="py-1" v-for="child in collapsible.children">
                          <NuxtLink :to="child.url.replace(config.public.prestashop_url, '')" @click="closMobileMenu" :key="child.id" class="px-2 py-1 hover:bg-gray-100 flex items-center gap-3">
                            <span>{{ child.label }}</span>
                          </NuxtLink>
                        </div>
                      </CollapsibleContent>
                    </Collapsible>
                  </template>
                </AccordionContent>
              </AccordionItem>
            </template>
          </Accordion>
        </nav>
      </SfDrawer>
    </transition>
  </header>
</template>

<style>
a {
  -webkit-tap-highlight-color: #dedede;
}
</style>
